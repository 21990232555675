import * as React from "react"
import styled from "styled-components"
import { styledMap } from "./_styled-map"
import {
  FontSizes,
  FontSizesMap,
  MarginSpacesMap,
  MarginSpaces,
} from "./_types"
import { device } from "../../utils/theme"

const margins: MarginSpacesMap<string> = {
  0: "0",
  1: "0.5rem",
  2: "1rem",
  3: "2rem",
  4: "3rem",
  5: "4rem",
}

const fontSizesDesktop: FontSizesMap<string> = {
  1: "3rem",
  2: "2rem",
  3: "1.125rem",
  4: "1rem",
  5: "1rem",
  6: "1rem",
}

const fontSizeMobile: FontSizesMap<string> = {
  1: "2rem",
  2: "1.5rem",
  3: "1.125rem",
  4: "1rem",
  5: "1rem",
  6: "1rem",
}

const StyledHeading = styled.h2<HeadingProps>`
  color: var(--heading);
  font-size: ${styledMap(props => props.size, fontSizeMobile)};
  margin-bottom: ${styledMap(
    props => (props.margin ? props.margin : 0),
    margins
  )};

  @media ${device.desktop.s} {
    font-size: ${styledMap(props => props.size, fontSizesDesktop)};
  }
`

interface HeadingProps {
  children: React.ReactNode
  size: FontSizes
  margin?: MarginSpaces
}

const Heading: React.FC<HeadingProps> = props => {
  return (
    <StyledHeading size={props.size} margin={props.margin}>
      {props.children}
    </StyledHeading>
  )
}

Heading.defaultProps = {
  size: 2,
  margin: 1,
}

export default Heading
