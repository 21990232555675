import React, { useEffect, useState } from "react"
import "../../assets/css/global.css"
import "../../assets/css/normalize.css"
import "../../assets/css/typography.css"
import { theme } from "../../utils/theme"
import { Theme } from "../../utils/types"
import { StateContext } from "../context"
import { Navigation } from "../Navigation"
import { Sidebar } from "../sidebar"
import { Main, StyledContainer, StyledLayout, StyledLink } from "./styles"
import { CurrentlyPlaying } from "../CurrentlyPlaying"

interface LayoutProps {
  title: string
  location?: Location
  noMargin?: boolean
}

export const useThemeFromLocalStorage = () => {
  const initialValue: Theme =
    typeof window !== "undefined" &&
    window &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"

  const [storedTheme, setStoredTheme] = useState<Theme>(() => {
    try {
      const item =
        typeof window !== "undefined" &&
        window &&
        (window.localStorage.getItem("theme") as Theme)
      return item ? item : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })

  const setTheme = (theme: Theme) => {
    try {
      setStoredTheme(theme)
      typeof window !== "undefined" &&
        window &&
        window.localStorage.setItem("theme", theme)
    } catch (error) {
      console.log(error)
    }
  }

  return [storedTheme, setTheme] as const
}

const Layout: React.FC<LayoutProps> = props => {
  const [themeMode, setThemeMode] = useThemeFromLocalStorage()
  const { title, children, noMargin } = props

  const logo = (
    <StyledLink to={`/`} title={title}>
      amber
    </StyledLink>
  )

  useEffect(() => {
    setThemeMode(themeMode)
  }, [])

  return (
    <StateContext.Provider value={{ theme: themeMode }}>
      <StyledContainer className={themeMode}>
        <StyledLayout>
          <Navigation
            logo={logo}
            theme={themeMode}
            updateTheme={theme => setThemeMode(theme)}
          />
          <Sidebar />

          <Main noMargin={noMargin}>{children}</Main>
          <footer
            style={{
              fontSize: "14px",
              marginTop: `${theme.spacing * 4}px`,
              padding: `${theme.spacing * 2}px 0`,
              paddingBottom: `${theme.spacing * 4}px`,
              borderTop: " 1px solid #373f51",
            }}
          >
            <CurrentlyPlaying />
          </footer>
        </StyledLayout>
      </StyledContainer>
    </StateContext.Provider>
  )
}

export default Layout
