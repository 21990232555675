import React from "react"
import styled from "styled-components"
import { theme } from "../../utils/theme"

interface ListProps {
  direction?: "row" | "column" | "row-reverse" | "column-reverse"
  spacing?: number
  children: React.ReactNode[]
  marker?: string
}

const StyledList = styled.ul<ListProps>`
  list-style: none;
  display: ${props => props.direction};
  li {
    position: relative;
    margin-bottom: ${props => props.spacing}px;
    padding-left: ${props => (props.marker ? theme.spacing * 3 : 0)}px;
    &:before {
      content: "${props => props.marker && props.marker}";
      position: absolute;
      left: 0px;
      color: var(--primary);
      transform: scale(0.75);
    }
  }
`

const StyledItem = styled.li`
  list-style-type: none;
`

export const List: React.FC<ListProps> = props => (
  <StyledList {...props}>
    {props.children.map((child, key) => (
      <StyledItem key={key}>{child}</StyledItem>
    ))}
  </StyledList>
)

List.defaultProps = {
  spacing: 0,
}
