import { createContext } from "react"
import { Theme } from "../utils/types"

interface Context {
  theme: Theme
}

const context: Context = {
  theme: "dark",
}

export const StateContext = createContext(context)
export const DispatchContext = createContext(context)
