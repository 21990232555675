interface Breakpoints {
  mobile: {
    s: number
    m: number
    l: number
  }
  tablet: {
    s: number
  }
  desktop: {
    s: number
    m: number
    l: number
  }
}

const breakpoints: Breakpoints = {
  mobile: {
    s: 320,
    m: 375,
    l: 414,
  },
  tablet: {
    s: 768,
  },
  desktop: {
    s: 1024,
    m: 1280,
    l: 1440,
  },
}

export const device = {
  mobile: {
    s: `(min-width: ${breakpoints.mobile.s}px)`,
    m: `(min-width: ${breakpoints.mobile.m}px)`,
    l: `(min-width: ${breakpoints.mobile.l}px)`,
  },
  tablet: {
    s: `(min-width: ${breakpoints.tablet.s}px)`,
  },
  desktop: {
    s: `(min-width: ${breakpoints.desktop.s}px)`,
    m: `(min-width: ${breakpoints.desktop.m}px)`,
    l: `(min-width: ${breakpoints.desktop.l}px)`,
  },
}

export const theme = {
  spacing: 8,
  border: {
    radius: 4,
  },
}
