import React from "react"
import styled from "styled-components"
import { theme, device } from "../../utils/theme"
import Github from "../../assets/images/github.svg"
import Email from "../../assets/images/email.svg"
import Twitter from "../../assets/images/twitter.svg"
import { List } from "../../assets/elements/list"
import { useStaticQuery, graphql } from "gatsby"
import { SideQueryQuery } from "../../../graphql-types"

const StyledSide = styled.div`
  position: relative;
  z-index: 100;
  bottom: ${theme.spacing * 4}px;
  font-family: "Cascadia";
  font-size: 0.875rem;
  left: ${theme.spacing * 6}px;
  display: none;

  @media ${device.desktop.s} {
    position: fixed;
    display: block;
  }
`

const StyledLink = styled.a`
  color: var(--text);

  svg {
    margin-right: ${theme.spacing}px;
    fill: var(--accent);
  }

  span {
    opacity: 0;
    transition: opacity 200ms;
  }

  &:hover {
    color: var(--text);
    span {
      opacity: 1;
    }
  }
`

export const Sidebar: React.FC<{}> = () => {
  const data: SideQueryQuery = useStaticQuery(graphql`
    query SideQuery {
      site {
        siteMetadata {
          author
          social {
            twitter
            instagram
            github
          }
        }
      }
    }
  `)

  const socialLinks = [
    <StyledLink
      key="twitter"
      href={
        "https://www.twitter.com/" + data.site?.siteMetadata?.social?.twitter
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      <Twitter /> <span>Twitter</span>
    </StyledLink>,
    <StyledLink
      key="github"
      href={"https://www.github.com/" + data.site?.siteMetadata?.social?.github}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Github /> <span>Github</span>
    </StyledLink>,
    <StyledLink
      key="email"
      href={"mailto: amberbrookhuis@gmail.com"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Email /> <span>Email</span>
    </StyledLink>,
  ]

  return (
    <StyledSide>
      <List spacing={theme.spacing * 2}>{socialLinks}</List>
    </StyledSide>
  )
}
