import styled from "styled-components"
import { theme, device } from "../../utils/theme"
import { Link } from "gatsby"

export const StyledLayout = styled.div`
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${theme.spacing * 3}px;
  color: var(--text);

  @media ${device.desktop.l} {
    padding: 0;
  }
`

export const StyledLink = styled(Link)`
  font-weight: 500;
  color: var(--heading);
  text-decoration: none;
`

export const StyledMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`

export const StyledMenuItem = styled(Link)`
  font-family: "Cascadia";
  font-size: 0.7rem;
  margin-left: ${theme.spacing * 3}px;
  color: var(--text);
  text-decoration: none;

  @media ${device.desktop.s} {
    margin-left: ${theme.spacing * 6}px;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: var(--accent);
    transform: translateY(3px);
    opacity: 0;
    transition: transform 200ms, opacity 200ms;
  }

  &:hover {
    &::after {
      opacity: 1;
      transform: translate(0);
    }
  }
`

export const StyledHeader = styled.header`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 ${theme.spacing * 3}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  z-index: 10;

  @media ${device.desktop.s} {
    padding: 0 ${theme.spacing * 6}px;
  }
`

export const StyledContainer = styled.div`
  padding-top: 80px;
  background: var(--main-bg-color);
  min-height: 100vh;
`

export const Main = styled.main<{ noMargin?: boolean }>`
  margin-top: ${props => (props.noMargin ? 0 : "50px")};

  @media ${device.desktop.s} {
    margin-top: ${props => (props.noMargin ? 0 : "100px")};
  }
`
