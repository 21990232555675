export const styledMap = <Props, Property extends string | number | symbol>(
  map: (_: Props) => Property,
  cssObject: Record<Property, string>
) => (props: Props): string => {
  const property = map(props)
  const value = cssObject[property]
  return value
}

export const styledMapDefault = <
  Props,
  Property extends string | number | symbol
>(
  map: (_: Props) => Property | undefined,
  cssObject: Record<Property, string> & { default: string }
) => (props: Props): string => {
  const property = map(props)
  if (!property) {
    return cssObject.default
  }
  const value = cssObject[property]
  return value
}

export const id = <T>(x: T) => x