import { Circle } from "@styled-icons/boxicons-solid/Circle"
import { Moon } from "@styled-icons/evaicons-solid/Moon"
import styled from "styled-components"
import { theme } from "../../utils/theme"
import { Theme } from "../../utils/types"
const DIMENSION = 20

export const StyledThemeToggle = styled.div`
  width: ${DIMENSION * 2}px;
  height: ${DIMENSION}px;
  position: relative;
  margin-left: ${theme.spacing * 5}px;
  cursor: pointer;
  display: flex;
`

export const StyledMoon = styled.div<{ themeMode: Theme }>`
  position: absolute;
  width: ${DIMENSION}px;
  height: ${DIMENSION}px;
  background: ${props => (props.themeMode === "dark" ? "orange" : "#32416c")};
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: ${props => (props.themeMode === "dark" ? 2 : 1)};
  transition: transform 200ms;
  transform: ${props =>
    props.themeMode === "dark" ? "translateY(0px)" : "translateY(-5px)"};
`

export const StyledSun = styled.div<{ themeMode: Theme }>`
  position: absolute;
  width: ${DIMENSION}px;
  height: ${DIMENSION}px;
  background: #32416c;
  top: 0;
  right: 0;
  border-radius: 50%;
  z-index: ${props => (props.themeMode === "dark" ? 1 : 2)};
  transition: transform 200ms;
  transform: ${props =>
    props.themeMode === "dark" ? "translateY(-5px)" : "translateY(0)"};
`

export const StyledOverlay = styled.div<{ themeMode: Theme }>`
  position: absolute;
  background: var(--main-bg-color);
  width: ${DIMENSION}px;
  height: ${DIMENSION}px;
  top: -5px;
  left: -10px;
  border-radius: 50%;
  z-index: 5;
  transition: transform 200ms;
  transform: ${props =>
    props.themeMode === "dark" ? "translateY(0px)" : "translateY(-5px)"};
`

export const MoonIcon = styled(Moon)<{ themeMode: Theme }>`
  width: ${DIMENSION}px;
  color: ${props =>
    props.themeMode === "dark" ? "var(--primary)" : "var(--dark-80)"};
  transition: transform 200ms;
  transform: translateY(0);
  &:hover {
    transform: translateY(3px);
  }
`
export const SunIcon = styled(Circle)<{ themeMode: Theme }>`
  width: ${DIMENSION}px;
  transition: transform 200ms;
  color: ${props =>
    props.themeMode === "dark" ? "var(--dark-80)" : "var(--accent)"};
  transition: transform 200ms;
  transform: translateY(0);
  &:hover {
    transform: translateY(3px);
  }
`

export const StyledLogo = styled.div`
  margin-top: -3px;
`
