import React from "react"
import { Theme } from "../../utils/types"
import { StyledHeader, StyledMenu, StyledMenuItem } from "../Layout/styles"
import { MoonIcon, StyledLogo, StyledThemeToggle, SunIcon } from "./styles"

interface Navigation {
  logo: JSX.Element
  theme: Theme
  updateTheme: (theme: Theme) => void
}

export const Navigation: React.FC<Navigation> = ({
  logo,
  theme,
  updateTheme,
}) => {
  return (
    <StyledHeader>
      <StyledLogo>{logo}</StyledLogo>
      <nav>
        <StyledMenu>
          <StyledMenuItem to="/about">about</StyledMenuItem>
          <StyledMenuItem to="/blog">blog</StyledMenuItem>
          <StyledThemeToggle
            onClick={() => updateTheme(theme === "dark" ? "light" : "dark")}
          >
            <MoonIcon themeMode={theme} />
            <SunIcon themeMode={theme} />
          </StyledThemeToggle>
        </StyledMenu>
      </nav>
    </StyledHeader>
  )
}
