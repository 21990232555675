import { Music2 } from "@styled-icons/remix-fill/Music2"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import moment from "moment"

type Playing = boolean

interface CurrentyPlaying {
  artist: string
  title: string
  playing: boolean | string
}

const StyledPlayCircle = styled(Music2)<{ playing: boolean | undefined }>`
  color: var(--primary);
  margin-top: -2px;
  margin-right: 12px;
`

const StyledCurrentlyPlaying = styled.div`
  font-family: "Cascadia";
  font-size: 0.7rem;
`

const StyledMinutes = styled.span`
  opacity: 0.5;
`

export const CurrentlyPlaying: React.FunctionComponent = () => {
  const [currentlyPlaying, setCurrentlyPlaying] = useState<CurrentyPlaying>()

  useEffect(() => {
    fetch("/.netlify/functions/music", {
      method: "GET",
    })
      .then(response => response.json())
      .then(data => {
        const track = data.recenttracks.track[0]
        const nowPlaying: boolean | undefined = track["@attr"]?.nowplaying

        const playing: Playing = nowPlaying ? nowPlaying : false

        setCurrentlyPlaying({
          artist: track.artist.name,
          title: track.name,
          playing: playing
            ? `(Now playing)`
            : `(${moment.unix(track.date.uts).fromNow()})`,
        })
      })
      .catch(err => {
        throw new Error(err)
      })
  }, [])

  return (
    <StyledCurrentlyPlaying>
      <StyledPlayCircle width={25} playing={false} />
      {!currentlyPlaying
        ? `Loading...`
        : `${currentlyPlaying.artist} - ${currentlyPlaying.title}`}
      <StyledMinutes>
        {currentlyPlaying && ` ${currentlyPlaying.playing}`}
      </StyledMinutes>
    </StyledCurrentlyPlaying>
  )
}
